import React, { useRef, useState } from "react";
import Slider from "react-slick";
import Layout from "@components/Layout";
import { Carousel, Button, Row, Col, Card } from "react-bootstrap";
import { graphql, useStaticQuery, Link } from "gatsby";
import { useLocation } from "@reach/router";
import { useMediaQuery } from "react-responsive";
import { Modal } from "react-responsive-modal";
import { X } from "react-feather";
import Img from "gatsby-image";
import BgImage from "gatsby-background-image";
import ArrowLeftGray from "@assets/images/icon/arrow-left-gray.svg";
import ArrowRightGray from "@assets/images/icon/arrow-right-gray.svg";
import ArrowLeftWhite from "@assets/images/icon/arrow-circle-left-white.svg";
import Video from "@components/Video";
import "react-responsive-modal/styles.css";

import {
  freeServices,
  materialVideos,
  measurementVideos,
  homeHeroCarouselsText,
} from "@utils/static-data";

const Home = () => {
  // state to hold active portofolio modal.
  const [activePortofolio, setActivePortofolio] = useState(null);
  // state modal open/close
  const [modal, setModal] = useState(false);
  // state to hold modal of active index
  const [activeIndex, setActiveIndex] = useState(0);
  // slider modal ref
  const modalRef = useRef();

  const img = useStaticQuery(graphql`
    query HomeQuery {
      heroCarouselsDesktop: allFile(
        filter: {
          dir: { regex: "/(bg)/(desktop)/" }
          name: { regex: "/(carousel)/" }
        }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            id
            base
            childImageSharp {
              fluid(maxHeight: 720, maxWidth: 1520, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      heroCarouselsMobile: allFile(
        filter: {
          dir: { regex: "/(bg)/(mobile)/" }
          name: { regex: "/(carousel)/" }
        }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            id
            base
            childImageSharp {
              fluid(maxHeight: 680, maxWidth: 350, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      highlight1: file(
        relativePath: { eq: "pages/desktop/home/highlight-1.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 300, maxWidth: 560, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      highlight2: file(
        relativePath: { eq: "pages/desktop/home/highlight-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 300, maxWidth: 560, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      highlight3: file(
        relativePath: { eq: "pages/desktop/home/highlight-3.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 300, maxWidth: 560, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      csImageDesktop: file(relativePath: { eq: "pages/desktop/home/cs.jpg" }) {
        childImageSharp {
          fixed(width: 666, height: 289, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      csImageMobile: file(relativePath: { eq: "pages/mobile/home/cs.jpg" }) {
        childImageSharp {
          fixed(width: 335, height: 150, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      grafik: file(relativePath: { eq: "pages/desktop/home/grafik.png" }) {
        childImageSharp {
          fluid(maxHeight: 426, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bgGrafikDesktop: file(relativePath: { eq: "bg/desktop/bg-grafik.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1005, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bgGrafikMobile: file(relativePath: { eq: "bg/mobile/bg-grafik.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 541, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      clientLogo: allFile(
        filter: { dir: { regex: "/(client-logo)/" }, name: { regex: "/()/" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            id
            base
            childImageSharp {
              fluid(maxHeight: 60, quality: 85) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      services: allFile(
        filter: {
          dir: { regex: "/(icon)/(services)/" }
          name: { regex: "/()/" }
        }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            id
            base
            publicURL
            extension
          }
        }
      }
      measurementGirl: file(
        relativePath: { eq: "pages/desktop/home/pemandu-bahan-ukuran.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 250, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kolaseDesktop: file(
        relativePath: { eq: "pages/desktop/home/kolase-jaket.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 2000, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kolaseMobile: file(
        relativePath: { eq: "pages/mobile/home/kolase-jaket.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 750, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      terfavorit: allFile(
        filter: { relativeDirectory: { regex: "/(portofolio)/" } }
        sort: { fields: [relativeDirectory, name], order: ASC }
        limit: 15
      ) {
        group(field: relativeDirectory) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 250, maxHeight: 340, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const imgKolase = [
    img.kolaseDesktop.childImageSharp.fluid,
    {
      ...img.kolaseMobile.childImageSharp.fluid,
      media: "(max-width: 1023.98px)",
    },
  ];

  const bgGrafik = [
    img.bgGrafikDesktop.childImageSharp.fluid,
    {
      ...img.bgGrafikMobile.childImageSharp.fluid,
      media: "(max-width: 1023.98px)",
    },
  ];

  const csImage = [
    img.csImageDesktop.childImageSharp.fixed,
    {
      ...img.csImageMobile.childImageSharp.fixed,
      media: "(max-width: 1023.98px)",
    },
  ];

  const { search } = useLocation();
  const isDesktop = useMediaQuery({ minWidth: 992 });

  // setting index before modal slider change
  const slideBeforeChange = (_, destinationIndex) => {
    setActiveIndex(destinationIndex);

    return destinationIndex;
  };

  // if portofolio images on click.
  const portofolioOnClick = (e, edges) => {
    e.preventDefault();

    var sort = edges.sort((a, b) => {
      return a.node.name == "main" ? -1 : b.node.name == "main" ? 1 : 0;
    });

    setActivePortofolio(sort);
    setModal(true);
  };

  // trigger event for modal while arrow on click.
  const modalPrevSlide = e => {
    e.preventDefault();
    modalRef.current.slickPrev();
  };

  // trigger event for modal while arrow on click.
  const modalNextSlide = e => {
    e.preventDefault();
    modalRef.current.slickNext();
  };

  // while modal on close.
  const modalOnClose = () => {
    setModal(false);
    setActiveIndex(0);
  };

  const renderHeroCarouselsDesktop = () => {
    return img.heroCarouselsDesktop.edges.map(({ node }, key) => (
      <Carousel.Item key={node.id}>
        <BgImage
          tag="div"
          fluid={node.childImageSharp.fluid}
          className="carousel-item-inner"
        >
          <div className="carousel-text" id={`text-${key + 1}`}>
            <div
              className="text-container"
              dangerouslySetInnerHTML={{
                __html: homeHeroCarouselsText[key].text,
              }}
            />
            <Button
              className="btn-order"
              as={Link}
              to={`/hubungi-kami/${search}`}
            >
              {homeHeroCarouselsText[key].btnText}
            </Button>
          </div>
        </BgImage>
      </Carousel.Item>
    ));
  };

  const renderHeroCarouselsMobile = () => {
    return img.heroCarouselsMobile.edges.map(({ node }, key) => (
      <Carousel.Item key={node.id}>
        <BgImage
          tag="div"
          fluid={node.childImageSharp.fluid}
          className="carousel-item-inner"
        >
          <div className="carousel-text" id={`text-${key + 1}`}>
            <div
              className="text-container"
              dangerouslySetInnerHTML={{
                __html: homeHeroCarouselsText[key].text,
              }}
            />
            <Button
              className="btn-order"
              as={Link}
              to={`/hubungi-kami/${search}`}
            >
              {homeHeroCarouselsText[key].btnText}
            </Button>
          </div>
        </BgImage>
      </Carousel.Item>
    ));
  };

  const renderOurClient = () => {
    let sortedClientData = [];

    if (img.clientLogo.edges.length > 0) {
      for (let i = 0; i < img.clientLogo.edges.length; i += 6) {
        sortedClientData.push(img.clientLogo.edges.slice(i, i + 6));
      }
    }

    return sortedClientData
      .filter(group => group.length === 6)
      .map((group, id) => (
        <Carousel.Item key={id}>
          <Row className="align-items-center">
            {group.map(({ node }) => (
              <Col
                key={node.id}
                xs={4}
                md={4}
                className="mb-3 d-flex align-items-center"
                style={{ width: 60, height: 60 }}
              >
                <Img
                  fluid={node.childImageSharp.fluid}
                  className="h-100 w-100"
                  alt="clients"
                  imgStyle={{ objectFit: "contain" }}
                />
              </Col>
            ))}
          </Row>
        </Carousel.Item>
      ));
  };

  const renderServices = () => {
    let services = [];

    for (let i in freeServices) {
      services.push(
        <div key={i} className="service-item">
          <img src={img.services.edges[i].node.publicURL} alt="service-item" />
          <span className="label">{freeServices[i].label}</span>
          <span>Gratis</span>
        </div>
      );
    }

    return services;
  };

  const renderMaterialVideos = () => {
    let newMaterialData = [].concat(materialVideos).slice(0, 4);
    return newMaterialData.map((video, idx) => (
      <div key={idx} className={`video-bahan-item ${video.class}`}>
        <Card>
          <Card.Body className="p-0">
            <Video
              quality={video.quality}
              link={video.link}
              imgStyle={{ borderRadius: 0 }}
            />
          </Card.Body>
        </Card>
        <p>{video.title}</p>
      </div>
    ));
  };

  const renderMeasurementVideos = () => {
    let newMeasurementData = [].concat(measurementVideos).slice(0, 4).reverse();
    return newMeasurementData.map((video, idx) => (
      <div key={idx} className={`video-pengukuran-item ${video.class}`}>
        <Card>
          <Card.Body className="p-0">
            <Video
              title={video.title}
              link={video.link}
              imgStyle={{ borderRadius: 0 }}
            />
          </Card.Body>
        </Card>
        <p>{video.title}</p>
      </div>
    ));
  };

  // Slick modal settings.
  const slickSettings = {
    customPaging: function (i) {
      return (
        <div
          key={activePortofolio[i].node.id}
          className={`navigations-img ${activeIndex === i && "modal-active"}`}
        >
          <Img
            fluid={
              activePortofolio[i].node.childImageSharp.fluid
                ? activePortofolio[i].node.childImageSharp.fluid
                : ""
            }
            alt="portofolio"
            style={{ width: 100 + "%", height: 100 + "%" }}
          />
        </div>
      );
    },
    className: "modal-slider",
    beforeChange: slideBeforeChange,
    ref: modalRef,
    dots: true,
    arrows: false,
    dotsClass: "list",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Layout>
      <main className="home">
        <header className="hero-header">
          <Carousel
            pause={false}
            prevIcon={<img src={ArrowLeftGray} />}
            nextIcon={<img src={ArrowRightGray} />}
            interval={3000}
          >
            {isDesktop
              ? renderHeroCarouselsDesktop()
              : renderHeroCarouselsMobile()}
          </Carousel>
        </header>
        <div className="content-container">
          <Row className="highlight">
            <Col md={12} className="highlight1">
              <Col className="highlight-img">
                <Img
                  fluid={img.highlight1.childImageSharp.fluid}
                  className="h-100"
                />
              </Col>
              <Col className="highlight-text">
                KAMI
                <br />
                ADALAH
                <br />
                KONVEKSI
                <br />
                JAKET ONLINE
                <br />
                <span style={{ color: "#f3611b" }}>NO.1</span> DI INDONESIA
              </Col>
            </Col>
            <Col md={12} className="highlight2">
              <Col className="highlight-img">
                <Img
                  fluid={img.highlight2.childImageSharp.fluid}
                  className="h-100"
                />
              </Col>
              <Col className="highlight-img">
                <Img
                  fluid={img.highlight3.childImageSharp.fluid}
                  className="h-100"
                />
              </Col>
            </Col>
          </Row>
          <Row className="cs">
            <Col>
              <div className="text-container">
                <h1>24 Jam dalam 7 Hari</h1>
                <p>
                  CS kami yang ramah
                  <br />
                  selalu siap mendampingi Anda
                </p>
              </div>
              <Img fixed={csImage} />
            </Col>
          </Row>
          <Row className="about">
            <div className="video">
              <Video
                link="https://www.youtube.com/watch?v=epEB_llv9NY"
                quality="sd"
                imgStyle={{ borderRadius: 0 }}
              />
            </div>
            <div className="title">
              <h3>
                <span style={{ color: "#f3611b" }}>Siapa</span>kah Kami?
              </h3>
            </div>
            <div className="text-container">
              <p>
                Kami adalah konveksi bikin jaket online no 1 di Indonesia. Kami
                berdiri sejak tahun 2014 di Depok, Jawa Barat.
              </p>
              <p>
                Kami mempunyai misi untuk membahagiakan para pekerja dan juga
                komunitas, dengan menciptakan pakaian yang nyaman dikenakan
                dalam kegiatan sehari-hari.
              </p>
            </div>
          </Row>
          <BgImage tag="div" fluid={bgGrafik} as={Row} className="grafik row">
            <Col md={12} className="grafik-container">
              <Img fluid={img.grafik.childImageSharp.fluid} className="bar" />
              <p className="text1">
                Dalam kurun waktu 6 Tahun,
                <br />
                Kami telah membantu Lebih dari
                <br />
                <span
                  style={{
                    color: "#db9a09",
                    fontWeight: "bold",
                  }}
                >
                  2.115 Project
                </span>{" "}
                pembuatan seragam.
              </p>
              <p className="text2">
                Semakin banyak klien baru yang kami bantu
                <br />
                setiap tahunnya. Dan juga, bertambah pula
                <br />
                pelanggan setia yang selalu terpuaskan
                <br />
                dengan seragamnya.
              </p>
            </Col>
          </BgImage>
          <Row className="desain-favorit">
            <Col md={12} className="title">
              <h3>
                Desain <span style={{ color: "#f3611b" }}>Terfavorit</span>
              </h3>
              <Button
                className="btn-more"
                as={Link}
                to={`/portfolio/${search}`}
              >
                Lihat Semua Jaket
              </Button>
            </Col>
            <Col md={12} className="desain-favorit-items">
              {img.terfavorit.group.length &&
                img.terfavorit.group.map((group, _) => {
                  const edge = group.edges.filter(
                    (edge, _) => edge.node.name === "main"
                  )[0];

                  return (
                    <div
                      className="desain-favorit-item"
                      key={edge.node.id}
                      onClick={e => portofolioOnClick(e, group.edges)}
                    >
                      <Img
                        fluid={edge?.node.childImageSharp.fluid}
                        alt={edge?.node.name}
                        style={{ width: 100 + "%", height: 100 + "%" }}
                      />
                    </div>
                  );
                })}
            </Col>
            <Button className="btn-more-mobile bnt-block">
              Lihat Semua Jaket
            </Button>
          </Row>
          <Row className="klien-services">
            <Col md={12} className="video">
              <Video
                link="https://www.youtube.com/watch?v=vQw7ldUsbK4"
                quality="sd"
                imgStyle={{ borderRadius: 0 }}
              />
            </Col>
            <Col md={12} className="clients">
              <div className="title">
                <h3>Klien Kami</h3>
                <Button
                  className="btn-more"
                  as={Link}
                  to={`/klien-kami/${search}`}
                >
                  Lihat Semua
                </Button>
              </div>
              <Carousel
                indicators={false}
                controls={false}
                pause={false}
                touch={false}
                interval={1500}
                className="client-list"
              >
                {renderOurClient()}
              </Carousel>
              <Button className="btn-more-mobile btn-block">Lihat Semua</Button>
            </Col>
            <Col md={12} className="title">
              <h4>
                <span className="title-orange">6 Layanan Gratis</span> Hanya
                Untuk Anda
              </h4>
            </Col>
            <Col md={12} className="services">
              {renderServices()}
            </Col>
          </Row>
          <Row className="measurements-banner">
            <Col md={8} className="text-container">
              <h3 className="font-weight-bold">
                Bingung gak bisa nentuin bahan dan ukuran?
              </h3>
              <p>
                <span>Jangan khawatir,</span> tonton video penjelasan bahan &
                juga <br />
                video cara pengukuran di bawah ini
              </p>
            </Col>
            <Col md={4}>
              <Img fluid={img.measurementGirl.childImageSharp.fluid} />
            </Col>
          </Row>
          <Row className="bahan">
            <Col md={12} className="title">
              <h3>Video Penjelasan Bahan</h3>
              <Button className="btn-more">Lihat Semua</Button>
            </Col>
            <Col md={12} className="video-bahan">
              {renderMaterialVideos()}
            </Col>
            <Col md={12} className="btn-more-mobile">
              <Button className="btn-more btn-block" as={Link} to="/bahan/">
                Lihat Semua
              </Button>
            </Col>
          </Row>
          <Row className="pengukuran mb-5">
            <Col md={12} className="title">
              <h3>Video Cara Pengukuran</h3>
              <Button className="btn-more" as={Link} to="/standar-ukuran">
                Lihat Semua
              </Button>
            </Col>
            <Col md={12} className="video-pengukuran">
              {renderMeasurementVideos()}
            </Col>
            <Col md={12} className="btn-more-mobile">
              <Button
                className="btn-more btn-block"
                as={Link}
                to="/standar-ukuran/"
              >
                Lihat Semua
              </Button>
            </Col>
          </Row>
          <Row className="showcase">
            <Col md={12} className="title">
              <h3>
                Follow Instagram kami di{" "}
                <a
                  href="https://instagram.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span style={{ color: "#f3611b" }}>@gudangseragam</span>
                </a>
              </h3>
            </Col>
            <Col md={12} className="kolase">
              <a
                href="https://instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img fluid={imgKolase} />
              </a>
            </Col>
          </Row>
        </div>
      </main>

      <Modal
        open={modal}
        onClose={modalOnClose}
        classNames={{ modal: "porto-modal", modalContainer: "modal-center" }}
        closeIcon={<X color="#fff" strokeWidth={3} />}
      >
        <div className="modal-wrapper">
          <Slider {...slickSettings}>
            {activePortofolio &&
              activePortofolio.map(({ node }) => (
                <div key={node.id} className="modal-img">
                  <Img
                    fluid={node.childImageSharp.fluid}
                    alt="portofolio"
                    style={{ width: 100 + "%", height: 100 + "%" }}
                  />
                </div>
              ))}
          </Slider>
          <div className="navigations">
            <div className="button-nav">
              <button onClick={e => modalPrevSlide(e)}>
                <img src={ArrowLeftWhite} />
              </button>
              <button onClick={e => modalNextSlide(e)} className="right">
                <img src={ArrowLeftWhite} />
              </button>
            </div>
            <div className="index">
              <span>{activeIndex + 1}</span>&nbsp;/&nbsp;
              {activePortofolio ? activePortofolio.length : 0}
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default Home;
